import * as React from "react";

import Page from "./../components/Layout/Page";
import Container from "../components/Layout/Container";

const ResourcesPage = () => {
  return (
    <Page className="resources">
      <section className="hero">
        <Container>
          <h1>
            <strong>Resources</strong>
          </h1>
        </Container>
      </section>
    </Page>
  );
};

export default ResourcesPage;
